import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import {
  Avatar,
  ButtonCardWithAvatar,
  ButtonLink,
  ContentContainerAppClient,
  Dialog,
  FlexColumn,
  RowButtonV2,
  RowContainerV2,
  Text,
  Tooltip,
  colorTokens,
  colors,
  downloadFile,
  mobileSideSpace,
  primaryColor,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
  useLanguage,
  useScreenType,
  useTranslate,
} from '@orus.eu/pharaoh'
import { RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { OrusHorizontalLogoImage } from '@orus.eu/pharaoh/src/skin/default-skin/orus-horizontal-logo-image'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback, useState } from 'react'
import { trpc } from '../../client'
import { dynamicFileNames } from '../../lib/download-filenames'
import {
  getMyCertificateDownloadLink,
  getMySpecificCertificateTravelAgenciesDownloadLink,
} from '../../lib/download-links'
import { getMutaSituation } from '../../lib/muta-util'
import { useWindowLocationHash } from '../../lib/use-window-location-hash'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { ContractDocumentDownloadRow } from '../organisms/contract-documents-download-row'
import { Loader } from '../organisms/loader'
import { WelcomeDialog } from '../organisms/welcome-dialog/welcome-dialog'
import { SmallScreenMargin } from '../templates/small-screen-margin'
import { RenewalCustomerNotification } from './renewal/RenewalCustomerNotification'

export function CustomerHomePage(): JSX.Element {
  const screenType = useScreenType()
  return (
    <div>
      {screenType === 'mobile' ? (
        <div
          css={css`
            padding: ${spacing[50]} ${spacing[60]};
          `}
        >
          <OrusHorizontalLogoImage width={56} height={19} color={primaryColor} />
        </div>
      ) : null}

      <Loader apiUsage={loadHomePageData} element={(data) => <HomePageContentBlock data={data} />} />
    </div>
  )
}

export default CustomerHomePage

async function loadHomePageData(): Promise<HomePageData> {
  const [userAccount, contracts] = await Promise.all([
    trpc.users.loadMyInformation.query(),
    trpc.contracts.listMyContracts.query(),
  ])
  return { userAccount, contracts }
}

type HomePageData = {
  userAccount: UserAccount
  contracts: CustomerContractDescription[]
}

type HomePageContentBlockProps = {
  data: HomePageData | null
}

function HomePageContentBlock({ data }: HomePageContentBlockProps): JSX.Element {
  const translate = useTranslate()
  const hash = useWindowLocationHash()
  const navigate = useNavigate()
  if (data === null) {
    // This is an edge case where the connected user has been deleted from the database.
    // Possible causes : test environment reset, GDPR requests, etc...
    // Handle by logging out
    trpc.sessions.logout.mutate(undefined).then(
      () => document.location.reload(),
      (err: unknown) => {
        throw err
      },
    )
    return <GlobalLoadingState />
  }

  const { contracts, userAccount } = data

  const welcomeModalOpen = hash === 'onboarding'

  const onClose = () => {
    void navigate({ hash: '', replace: true })
  }

  if (contracts.length === 0) {
    // Should only happen for Orus agents (customers have to go through the subscription process)
    return (
      <>
        <Text variant="body2" css={{ margin: `${spacing[60]} ${mobileSideSpace}` }}>
          {translate('welcome_no_contracts')}
        </Text>
      </>
    )
  }

  const customerEmail = 'email' in userAccount ? userAccount.email : userAccount.unverifiedEmail
  return (
    <ContentContainerAppClient gap="0">
      <QuickAccessBlock userAccount={userAccount} contracts={contracts} customerEmail={customerEmail} />
      {welcomeModalOpen ? (
        <WelcomeDialog onClose={onClose} contracts={contracts} customerEmail={customerEmail} />
      ) : (
        <></>
      )}
    </ContentContainerAppClient>
  )
}

type QuickAccessBlockProps = {
  userAccount: UserAccount
  contracts: CustomerContractDescription[]
  customerEmail: string
}

function QuickAccessBlock({ userAccount, contracts, customerEmail }: QuickAccessBlockProps): JSX.Element {
  const translate = useTranslate()
  const { isMuta, isMutaOnly } = getMutaSituation(contracts)

  const navigate = useNavigate()

  const onContractClick = useCallback(() => {
    void navigate({ to: '/contracts' })
  }, [navigate])
  const onHealthInsuranceClick = useCallback(() => {
    void navigate({ to: '/health-insurance' })
  }, [navigate])
  const onGuaranteesClick = useCallback(() => {
    void navigate({ to: '/contract-guarantees' })
  }, [navigate])
  const onInvoiceClick = useCallback(() => {
    void navigate({ to: '/invoices' })
  }, [navigate])
  const onReportClaimClick = useCallback(() => {
    void navigate({ to: '/report-claim' })
  }, [navigate])

  return (
    <SmallScreenMargin>
      <Text
        variant="h2"
        css={css`
          padding-top: ${spacing[60]};
        `}
      >
        <WelcomeSentence userAccount={userAccount} />
      </Text>

      <RenewalCustomerNotification
        css={css`
          margin-top: ${spacing[70]};
        `}
      />

      <Text
        css={css`
          margin-top: ${spacing[70]};
        `}
        variant="subtitle2"
      >
        {translate('quick_access')}
      </Text>

      <div
        css={css`
          margin-top: ${spacing[50]};
          display: ${isMutaOnly ? undefined : 'grid'};
          grid-template-columns: ${isMutaOnly ? undefined : 'repeat(2, minmax(0, 1fr))'};
          justify-content: center;
          gap: ${spacing[30]};
        `}
      >
        <DownloadMyCertificateButton contracts={contracts} customerEmail={customerEmail} />
        {isMutaOnly ? undefined : (
          <ButtonCardWithAvatar
            text={translate('report_claim')}
            avatarColor={colors.peach.gradient}
            avatarIcon="bell-light"
            onClick={onReportClaimClick}
          />
        )}
      </div>

      <RowContainerV2
        css={css`
          margin-top: ${spacing[70]};
          border: none;
          background-color: ${colors.linen};
        `}
      >
        <RowButtonV2
          primaryText={translate('my_contracts')}
          avatarLeft={<Avatar icon="folder-solid" color={colorTokens['color-fg-base-active-inverse']} size="30" />}
          onClick={onContractClick}
        />
        {isMuta ? (
          <RowButtonV2
            primaryText={translate('my_health_insurance')}
            avatarLeft={<Avatar icon="heart-solid" color={colorTokens['color-fg-base-active-inverse']} size="30" />}
            onClick={onHealthInsuranceClick}
          />
        ) : undefined}
        <RowButtonV2
          primaryText={translate('my_guarantees')}
          avatarLeft={<Avatar icon="umbrella-solid" color={colorTokens['color-fg-base-active-inverse']} size="30" />}
          onClick={onGuaranteesClick}
        />
        <RowButtonV2
          primaryText={translate('my_invoices')}
          avatarLeft={<Avatar icon="receipt-solid" color={colorTokens['color-fg-base-active-inverse']} size="30" />}
          onClick={onInvoiceClick}
        />
      </RowContainerV2>
    </SmallScreenMargin>
  )
}

type DownloadMyCertificateButtonProps = {
  contracts: CustomerContractDescription[]
  customerEmail: string
}

function DownloadMyCertificateButton({ contracts, customerEmail }: DownloadMyCertificateButtonProps): JSX.Element {
  const translate = useTranslate()
  const language = useLanguage()
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)
  const hasMultipleCertificates =
    contracts.length > 1 ||
    contracts.some(
      (contract) =>
        contract.activity.activity === '509334fe-6786-468a-92d8-052550f5d6fc' || // Agence de voyage / Tour opérateur
        contract.activity.activity === '278322e7-a0e8-45f0-89d9-cc02a2f524f6' || // Guide touristique
        contract.activity.activity === 'a5cfbff0-8671-4555-b12f-acc4201dfe22', // Agent commercial immobilier,
    )

  const {
    hide: hideCertificateSelectionDialog,
    show: showCertificateSelectionDialog,
    visible: isCertificateSelectionDialogVisible,
  } = useDialogVisibility('select-certificate')

  const downloadContractCertificate = useAsyncCallback(
    async (contract: CustomerContractDescription) => {
      const downloadCertificateLink = getMyCertificateDownloadLink(contract.subscriptionId)
      const protectionStatus = contract.currentProtectionStatus

      if (protectionStatus === 'suspended') {
        alert(translate('your_contract_is_suspended'))
        return
      }

      if (protectionStatus === 'terminated') {
        alert(translate('your_contract_is_terminated'))
        return
      }

      try {
        setDownloadInProgress(true)
        await downloadFile(downloadCertificateLink, dynamicFileNames['insurance-certificate'](customerEmail, language))
      } finally {
        setDownloadInProgress(false)
      }
    },
    [customerEmail, language, translate],
  )

  const handleDownloadClick = useCallback(() => {
    if (!hasMultipleCertificates) {
      downloadContractCertificate(contracts[0])
      return
    }

    showCertificateSelectionDialog()
  }, [contracts, downloadContractCertificate, showCertificateSelectionDialog, hasMultipleCertificates])

  return (
    <>
      <ButtonCardWithAvatar
        text={translate(hasMultipleCertificates ? 'download_my_certificates' : 'download_my_certificate')}
        onClick={handleDownloadClick}
        disabled={downloadInProgress}
        downloadInProgress={downloadInProgress}
        avatarColor={colors.periwinkle.gradient}
        avatarIcon="arrow-down-to-line-light"
      />
      {isCertificateSelectionDialogVisible ? (
        <ContractSelectionDialog contracts={contracts} onClose={hideCertificateSelectionDialog} />
      ) : (
        <></>
      )}
    </>
  )
}

type WelcomeSentenceProps = {
  userAccount: UserAccount
}

function WelcomeSentence({ userAccount }: WelcomeSentenceProps): JSX.Element {
  const translate = useTranslate()
  if (userAccount.firstName) {
    return (
      <>
        <Text variant="h3">{translate('customer_app_welcome_title', { firstName: userAccount.firstName })}</Text>
      </>
    )
  }

  return <>{translate('welcome_to_orus')}</>
}

type ContractSelectionDialogProps = {
  contracts: CustomerContractDescription[]
  onClose: () => void
}
const ContractSelectionDialog = memo<ContractSelectionDialogProps>(function ContractSelectionDialog({
  contracts,
  onClose,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  return (
    <Dialog title={translate('download_your_certificates')} size="medium" onClose={onClose}>
      <DocumentCard>
        {contracts
          .filter((contract) => ['active', 'not-started'].includes(contract.currentProtectionStatus))
          .map((contract) => (
            <div
              css={{ display: 'flex', flexDirection: 'column', gap: spacing[50], padding: '0' }}
              key={contract.subscriptionId}
            >
              <Text variant="body2Medium">{`${translate('contract')} ${contract.contractNumber}`}</Text>
              <RowContainer variant="border" size="medium">
                <ContractDocumentDownloadRow
                  key={contract.subscriptionId}
                  doc={{
                    method: 'get' as const,
                    fileName: dynamicFileNames['insurance-certificate'](contract.contractNumber, language),
                    fileType: 'insurance-certificate',
                    url: getMyCertificateDownloadLink(contract.subscriptionId),
                  }}
                />
                {contract.activity.activity === '509334fe-6786-468a-92d8-052550f5d6fc' ||
                contract.activity.activity === '278322e7-a0e8-45f0-89d9-cc02a2f524f6' ? (
                  <ContractDocumentDownloadRow
                    key={contract.subscriptionId}
                    doc={{
                      method: 'get' as const,
                      fileName: dynamicFileNames['specific-insurance-certificate-travel-agencies'](
                        contract.contractNumber,
                        language,
                      ),
                      fileType: 'specific-insurance-certificate-travel-agencies',
                      url: getMySpecificCertificateTravelAgenciesDownloadLink(contract.subscriptionId),
                    }}
                  />
                ) : null}
              </RowContainer>
              {contract.activity.activity === 'a5cfbff0-8671-4555-b12f-acc4201dfe22' ? (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-between',
                    alignItems: 'center',
                    gap: spacing[50],
                  }}
                >
                  <Text variant="body2">
                    Attestation d’assurance de la RC Professionnelle des agents commerciaux habilités.
                  </Text>
                  <Tooltip title="Créer une attestation habilitation CCI">
                    <ButtonLink
                      variant="secondary"
                      size="small"
                      icon="plus-regular"
                      avatarPosition="left"
                      to={`/insurance-certificate-generator-real-estate-sales-agents/${contract.subscriptionId}`}
                    >
                      {'Attestation CCI'}
                    </ButtonLink>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          ))}
      </DocumentCard>
    </Dialog>
  )
})

const DocumentCard = styled(FlexColumn)`
  gap: ${spacing[70]};
  padding: 0;
`
